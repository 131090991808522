import { useParams } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import NjukoApi from "../../api/njuko";
import NeurunApi from "../../api/neurun";
import {setToken} from "../../api/njuko/axiosInstance";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import Logo from '../../assets/img/neurun-logo.svg';
import CustomTextField from "../../components/CustomTextField";
import CustomFileUpload from "../../components/CustomFileUpload";
import RemoveIcon from "../../assets/img/remove-icon.svg";
import CelebrationIcon from "../../assets/img/celebration.svg";
import Autocomplete from "react-google-autocomplete";
import { geocode, RequestType, setKey } from "react-geocode";
import CustomMap from "../../components/CustomMap";
import { GpxWaypoints, Track } from "../../types/models";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import { countries, languages, surfaceTypes, unitOptions } from "../../constants";
import CustomDateTimePicker from "../../components/CustomDateTimePicker";
import CustomSelectField from "../../components/CustomSelect";
import { distanceConverter } from "../../helpers/distanceConverter";
import LinkIcon from "../../assets/img/link-icon.svg";
import ChipInputComponent from "../../components/ChipInput";
import { getBaseUrl } from "../../api/neurun/axiosInstance";
import dayjs from "dayjs";
import { validateDateTime } from "../../helpers/dateVlidator";

type NjukoEdition = {
    reportName: string;
    editionId: string;
    status: string;
    event: string;
    startDate: string;
    timezone: string;
    country: string;
    countryName: string;
    url: string;
    sport: string;
    languages: string[]
}

type GpxFile = {
    guid: string;
    tracks: Record<string, any>[];
    waypoints: Record<string, any>[];
    total_distance: number;
    avg_elevation: number;
    start_point: Record<string, any>
    end_point: Record<string, any>
    surface_type: string;
}

export const mapContainerStyle = {
    height: '400px',
    width: '100%',
}

export const shapeExampleStyles = {
    container: mapContainerStyle,
}

type Coordinates = {
    lat: number,
    lng: number
}

type NeurunRace = {
    id: number,
    guid: string,
    name: string,
    distance: string,
    website_url: string,
    registration_url: string,
    surface_type: string,
    starting_at: string,
    gpx_file_guid: string,
    location: string,
    coordinates: Coordinates,
    units: string,
    created_by_user: string,
    resource_urls: string,
    images: string[],
    logo: NeurunFile,
    file: NeurunFile
}

type NeurunFile = {
    created_at: string,
    file_url: string,
    filename: string,
    guid: string
    id: string
}

const HomePage = () => {
    const { edition_id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [edition, setEdition] = useState<NjukoEdition | null>(null)
    const [neurunEdition, setNeurunEdition] = useState<NeurunRace | null>(null)
    const [gpxFile, setGpxFile] = useState<GpxFile | null>(null)
    const [fileName, setFileName] = useState('');
    const [gpxErrorMessage, setGpxErrorMessage] = useState('');
    const [imageErrorMessage, setImageErrorMessage] = useState('');
    const [pdfFileError, setPdfFileError] = useState('');
    const [raceLogoError, setRaceLogoError] = useState('');
    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const [selectedLogo, setSelectedLogo] = useState<File[] | string>([]);
    const [selectedLogoName, setSelectedLogoName] = useState<string>('');
    const [locationCoordinates, setLocationCoordinates] = useState('')
    const [location, setLocation] = useState('');
    const [startPoint, setStartPoint] = useState<Coordinates | null>()
    const [endPoint, setEndPoint] = useState<Coordinates | null>()
    const [path, setPath] = useState<Coordinates[]>([]);
    const [waypoints, setWaypoints] = useState<GpxWaypoints[]>([]);
    const [pdfFile, setPdfFile] = useState<File | null>();
    const [logoFIle, setLogoFile] = useState('');
    const [gpxGuid, setGpxGuid] = useState('');
    const [gpxTracks, setGpxTracks] = useState<Track[]>([]);
    const [saveGpx, setSaveGpx] = useState(false);
    const [locationCoord, setLocationCoord] = useState<Coordinates | null>()
    const [isRaceSaving, setIsRaceSaving] = useState(false);
    const [startDateError, setStartDateError] = useState<{ valid: boolean, message: string }>({ valid: false, message: '' });
    const [gpxUpdated, setGpxUpdated] = useState(false);
    const [isRaceSaved, setIsRaceSaved] = useState(false);

    const autocompleteRef = useRef<HTMLInputElement>(null);
    const gpxUpdateResolve = useRef<(() => void) | null>(null);

    const formik = useFormik({
        initialValues: {
            registration_url: '',
            name: '',
            country: '',
            date: '',
            language: '',
            surface_type: '',
            pdf_file: '',
            race_logo: '',
            logoUrl: '',
            race_site_url: '',
            distance: '',
            units: 'metric',
            resource_urls: []
        },
        validationSchema: yup.object({
            name: yup.string().required('Race name is required.'),
            language: yup.object().required('Language is required'),
            country: yup.object().required('Country is required'),
            surface_type: yup.string().required('Surface type is required'),
            distance: yup.number(),
            registration_url: yup.string()
              .url('Please enter a valid URL.'),
            race_site_url: yup.string()
              .url('Please enter a valid URL.'),
            resource_urls: yup.array()
              .of(yup.string().url('Please enter a valid URL.'))
              .max(22, 'You can only add up to 22 Resource URLs'),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            if (!edition) return;

            setIsRaceSaving(true);
            const formData = new FormData();

            const distance = values.units === 'imperial' ? distanceConverter(+values.distance, 'metric') : values.distance

            if (edition_id) {
                formData.append('external_id', edition_id);
            }
            if (values.race_site_url) {
                formData.append('website_url', values.race_site_url || '');
            }
            formData.append('registration_url', values.registration_url || '');
            formData.append('name', values.name);
            if (gpxFile) {
                formData.append('gpx_file_guid', gpxFile.guid);
            }
            formData.append('distance', String(distance));
            if (locationCoord) {
                formData.append('coordinates', JSON.stringify({lon: locationCoord.lng, lat: locationCoord.lat}));
            }
            formData.append('location', location);
            formData.append('starting_at', values.date);
            formData.append('surface_type', values.surface_type);
            formData.append('units', values.units?.toLowerCase());
            formData.append('is_public', 'true');

            if (values.resource_urls.length) {
                for (let i = 0; i < values?.resource_urls.length; i++) {
                    formData.append('resource_urls', values?.resource_urls[i]);
                }
            }


            if (pdfFile) {
                formData.append('file', pdfFile)
            }

            if (selectedLogo) {
                for (let i = 0; i < selectedLogo?.length; i++) {
                    if (typeof selectedLogo !== 'string') {
                        formData.append('logo', selectedLogo[i]);
                    }
                }
            }

            // for (let i = 0; i < selectedImages.length; i++) {
            //     formData.append('images', selectedImages[i])
            // }

            try {
                setIsRaceSaved(false)
                setSaveGpx(true);
                await waitForGpxUpdate();
                if (!neurunEdition?.guid) {
                    const race = await NeurunApi.createRace(formData);
                    setNeurunEdition(race);
                } else {
                    const race = await NeurunApi.updateRace(formData, neurunEdition?.guid);
                    setNeurunEdition(race);
                }
                window.scrollTo(0, 0);
                setIsRaceSaved(true);
                setIsRaceSaving(false);
            } catch (error) {
                setIsRaceSaving(false);
                setSaveGpx(false);
                console.error('Error creating race:', error);
            }
        },
    });

    const waitForGpxUpdate = () => {
        return new Promise<void>((resolve) => {
            if (gpxUpdated) {
                resolve();
            } else {
                gpxUpdateResolve.current = resolve;
            }
        });
    };

    const handleGpxUpdateComplete = () => {
        setGpxUpdated(true);
    };

    const isDisabled = useMemo(() => {
        return !((gpxFile || location) && edition) || !!imageErrorMessage || !!gpxErrorMessage || !formik.values.language || !formik.values.surface_type;
    }, [gpxFile, edition, imageErrorMessage, gpxErrorMessage, location, formik.values]);

    useEffect(() => {
        if (gpxUpdated && gpxUpdateResolve.current) {
            gpxUpdateResolve.current();
            gpxUpdateResolve.current = null;
        }
    }, [gpxUpdated]);

    useEffect(() => {
        getEditionData()
        setKey(process.env.REACT_APP_GOOGLE_API_KEY || '')
    }, [])

    useEffect(() => {
        if (edition) {
            const selectedCountry = countries.find(i => i.code === edition.country);
            const englishLanguageIndex = edition.languages.findIndex(i => i === 'en_US');

            if (englishLanguageIndex !== -1) {
                formik.setFieldValue('language', { label: 'English', code: 'en-US' })
                // formik.setFieldValue('language', 'English')
            }

            formik.setFieldValue('surface_type', edition?.sport || '');
            formik.setFieldValue('name', edition.reportName || '');
            formik.setFieldValue('date', dayjs(edition.startDate)?.utc().format() || '');
            formik.setFieldValue('registration_url', edition.url ? `https://in.njuko.com/${edition.url}` : '');
            formik.setFieldValue('country', selectedCountry || '');
        }
    }, [edition]);

    useEffect(() => {
        if (neurunEdition) {
            getGpxData(neurunEdition?.guid).then(res => {
                // @ts-ignore
                setGpxData(res);
                setLocation(neurunEdition?.location);
                formik.setFieldValue('date', dayjs(neurunEdition.starting_at)?.utc().format() || '');
                formik.setFieldValue('name', neurunEdition.name || '');
                formik.setFieldValue('distance', neurunEdition?.distance);
                formik.setFieldValue('surface_type', neurunEdition?.surface_type);
                formik.setFieldValue('race_site_url', neurunEdition?.website_url || '');
                formik.setFieldValue('pdf_file', neurunEdition?.file?.filename || '');
                if (isValidArray(neurunEdition?.resource_urls)) {
                    formik.setFieldValue('resource_urls', neurunEdition?.resource_urls || []);
                }
                setSelectedLogo(neurunEdition?.logo?.file_url)
                setSelectedLogoName(neurunEdition?.logo?.filename || '');
            });
        }
    }, [neurunEdition]);

    const getGpxData = async (gpx_file_guid: string) => {
        const gpxData = await NeurunApi.getGpxData(gpx_file_guid);
        return gpxData;
    }

    useEffect(() => {
        const validation = validateDateTime(formik.values.date);
        setStartDateError(validation);
    }, [formik.values.date]);

    useEffect(() => {
        if (locationCoordinates) {
            geocode(RequestType.LATLNG, locationCoordinates)
              .then(({ results }) => {
                  const address = results[0].formatted_address;
                  setLocation(address);
                  if (autocompleteRef.current) {
                      autocompleteRef.current.value = address
                  }
              })
              .catch(console.error);
        }
    }, [locationCoordinates]);

    const getEditionData = async () => {
        try {
            setIsLoading(true)
            if (!edition_id) return
            const res = await NeurunApi.getNjukoToken(edition_id)
            setToken(res.token)
            const result = await NjukoApi.getEditionInfo(edition_id)
            setEdition(result)
            const neurunEdition = await NeurunApi.getRace(edition_id)
            setNeurunEdition(neurunEdition)
        } finally {
            setIsLoading(false)
        }
    }

    const isValidArray = (arr: string[] | any) => {
        return Array.isArray(arr) && arr.length > 0 && !arr.includes(null);
    };

    const onPdfSelect = (file: FileList | null) => {
        if (!file?.length) return;

        const selectedFile = file[0];
        const isSpaceContains = /\s/.test(selectedFile.name);

        if ((selectedFile.type !== 'application/pdf' && !selectedFile.name.endsWith('.pdf')) || isSpaceContains) {
            setPdfFileError('Please upload a valid PDF file.');
            return;
        }

        setPdfFile(selectedFile);
        setPdfFileError('');
        formik.setFieldValue('pdf_file', file[0]?.name)
    }

    const onLogoSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const validImage: File[] = [];

            const files = Array.from(event.target.files);
            files.forEach((file) => {
                if (file.type.startsWith('image/')) {
                    setLogoFile(file?.name);
                    formik.setFieldValue('logoUrl', file?.name)
                    validImage.push(file);
                } else {
                    setRaceLogoError('Please select valid image file.');
                }
            });

            setSelectedLogo(validImage);
        }
    }

    const sendGPXFile = async (file: FileList | null) => {
        if (!file?.length) return

        if (file[0].type !== 'application/gpx+xml' && file[0].name.split('.').pop()?.toLowerCase() !== 'gpx') {
            setGpxErrorMessage('Please upload a valid GPX file.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file[0]);
            setFileName(file[0]?.name)
            setGpxErrorMessage('');

            const res = await NeurunApi.uploadGpx(formData)
            if (!res) return;
            setGpxData(res);
        } catch (e) {
            console.log();
        }
    }


    const setGpxData = (data: GpxFile) => {
        formik.setFieldValue('distance', data?.total_distance)
        setGpxGuid(data.guid);
        // @ts-ignore
        setGpxTracks(data.tracks);
        setLocationCoordinates(`${data.start_point.lat},${data.start_point.lon}`)
        // @ts-ignore
        setPath(getMappedPath(data?.tracks[0]))
        // @ts-ignore
        setWaypoints(data?.waypoints)
        setStartPoint({ lat: data.start_point.lat, lng: data.start_point.lon });
        setEndPoint({ lat: data.end_point.lat, lng: data.end_point.lon });
        setGpxFile(data)
        setLocationCoord({ lat: data.start_point.lat, lng: data.start_point.lon })
    }

    const getMappedPath = (track: Track | GpxWaypoints) => {
        if (track) {
            return track?.geometry?.coordinates.map(i => ({ lat: i.lat, lng: i.lon }))
        } else {
            return []
        }
    }

    const onRemoveFile = () => {
        setFileName('');
        setGpxFile(null);
        setStartPoint(null);
        setEndPoint(null)
    }

    const onRemovePdfFile = () => {
        setPdfFile(null);
        formik.setFieldValue('pdf_file', '')
    };

    const onRemoveLogoFile = () => {
        setSelectedLogo([]);
        setSelectedLogoName('');
        setLogoFile('');
    };

    const handleAddChip = (chip: string) => {
        if (!chip.length) return;
        if (formik.values.resource_urls.length < 22) {
            formik.setFieldValue('resource_urls', [...formik.values.resource_urls, chip]);
        } else {
            formik.setErrors({resource_urls: 'You can only add up to 22 Resource URLs'});
        }
    };

    const handleDeleteChip = (chipToDelete: string) => {
        const resourceUrls: string[] = formik.values.resource_urls;
        formik.setFieldValue('resource_urls', resourceUrls.filter((chip) => chip !== chipToDelete));
        if (resourceUrls.length <= 22) {
            formik.setErrors({resource_urls: ''})
        }
    };


    const onRaceImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const validImageFiles: File[] = [];

            files.forEach((file) => {
                if (file.type.startsWith('image/')) {
                    validImageFiles.push(file);
                } else {
                    setImageErrorMessage('Please select valid image file.');
                }
            });

            setSelectedImages((prevImages) => [...prevImages, ...validImageFiles]);
        }
    };

    const onRemoveImageFile = (index: number) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleUpdatePath = (path: Coordinates[]) => {
        // console.log('Updated Path:', updatedPath);
        // setUpdatedPath('')
    };

    const copyNeurunLink = () => {
        const url = getBaseUrl();
        const link = `${url}/my-races/race/${neurunEdition?.guid}`
        navigator.clipboard.writeText(link);
    }

    const renderContent = () => {
        if (isLoading) {
            return (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mt={4}
              >
                  <CircularProgress color='info' />
              </Box>
            )
        }

        if (isRaceSaved) {
            return (
              <Box mt={3} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                  <img src={CelebrationIcon} width='60px' height='60px' alt='Celebration' />
                  <Box>
                      <Typography fontWeight='700' fontSize='28px' textAlign="center">Race added successfully</Typography>
                  </Box>
                  <Box mt={2}>
                      <button type='button' style={styles.copyBtn} onClick={copyNeurunLink}>
                          <img src={LinkIcon} alt='Link' style={styles.copyIcon} />
                          <span>Copy Neurun Link</span>
                      </button>
                  </Box>
              </Box>
            )
        }

        return (
          <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}} mt={4}>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                          <CustomTextField
                            title='Race Name'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            name='name'
                            required
                            placeholder='Enter Race Name'
                            onBlur={formik.handleBlur}
                            error={formik.errors.name && formik.touched.name ? formik.errors.name : ''}
                          />
                          <CustomAutocomplete
                            title='Select language'
                            name='language'
                            required
                            onChange={(value) => formik.setFieldValue('language', value)}
                            value={formik.values.language}
                            placeholder='Select language'
                            options={languages}
                            onBlur={formik.handleBlur}
                            error={formik.errors?.language && formik.touched.language ? formik.errors?.language : ''}
                          />
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                          <CustomFileUpload
                            id='gpxUpload'
                            onChange={(e) => sendGPXFile(e.target.files)}
                            title='Select GPX file for your run'
                            subtext='Tap to upload a GPX file'
                            acceptType='.gpx'
                            error={gpxErrorMessage}
                          />
                          {gpxFile && fileName ? (
                            <Box sx={{display: 'flex', gap: '8px', justifyContent: 'space-between', marginTop: '-4px'}}>
                                <span>{fileName}</span>
                                <img
                                  src={RemoveIcon}
                                  alt='Remove'
                                  width='20px'
                                  height='20px'
                                  onClick={onRemoveFile}
                                  style={{cursor: 'pointer'}}
                                />
                            </Box>
                          ) : ''}
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <CustomDateTimePicker
                        title='Select start date'
                        name='date'
                        required
                        onChange={(value) => {
                            if (value) {
                                const isoDate = value.utc().format();
                                formik.setFieldValue('date', isoDate);
                            }
                        }}
                        value={formik.values.date}
                        error={startDateError?.message}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'grid'}}>
                          <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
                              Location
                              <span style={styles.requiredField}>*</span>
                          </Typography>
                          <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            ref={autocompleteRef}
                            defaultValue={location}
                            inputAutocompleteValue={location}
                            style={{
                                border: '0.5px solid #ccc',
                                borderRadius: '8px',
                                padding: '12px 14px',
                                fontSize: '1rem'
                            }}
                            onPlaceSelected={(place) => {
                                if (place?.formatted_address) {
                                    const lat = place.geometry?.location?.lat();
                                    const lng = place.geometry?.location?.lng();
                                    if (lat && lng) {
                                        setLocationCoord({ lat, lng });
                                    }
                                    setLocation(place?.formatted_address)
                                }
                            }}
                          />
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <CustomAutocomplete
                        title='Select a country'
                        name='country'
                        required
                        onChange={(value) => formik.setFieldValue('country', value)}
                        value={formik.values.country}
                        placeholder='Select a country'
                        options={countries}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <CustomTextField
                        title='Distance'
                        onChange={(event) => formik.setFieldValue('distance', event.target.value)}
                        value={formik.values.distance}
                        name='distance'
                        placeholder='Ex. 26.2'
                        onBlur={formik.handleBlur}
                        numberInput
                        error={formik.errors.distance && formik.touched.distance ? formik.errors.distance : ''}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <CustomTextField
                        title='Race Site URL'
                        onChange={formik.handleChange}
                        value={formik.values.race_site_url}
                        name='race_site_url'
                        onBlur={formik.handleBlur}
                        placeholder='Enter URL'
                        error={formik.errors.race_site_url && formik.touched.race_site_url ? formik.errors.race_site_url : ''}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <CustomSelectField
                        title='Measure unit'
                        name='units'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.units}
                        placeholder='Select unit'
                        options={unitOptions}
                        error={formik.errors.units && formik.touched.units ? formik.errors.units : ''}
                      />
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                          <CustomTextField
                            title='Race Registration URL'
                            onChange={formik.handleChange}
                            value={formik.values.registration_url}
                            name='registration_url'
                            placeholder='Enter URL'
                            onBlur={formik.handleBlur}
                            error={formik.errors.registration_url && formik.touched.registration_url ? formik.errors.registration_url : ''}
                          />
                          <CustomSelectField
                            title='Surface Type'
                            name='surface_type'
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.surface_type}
                            placeholder='Select Surface Type'
                            options={surfaceTypes}
                            error={formik.errors.surface_type && formik.touched.surface_type ? formik.errors.surface_type : ''}
                          />
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                          <CustomFileUpload
                            id='pdfUpload'
                            onChange={(e) => onPdfSelect(e.target.files)}
                            title='Select PDF file'
                            subtext='Tap to upload a PDF file'
                            acceptType='.pdf'
                            error={pdfFileError}
                            tooltipText='Upload additional race information in PDF format. This will be used to help the AI answer participant questions more accurately.'
                          />
                          {formik.values.pdf_file ? (
                            <Box sx={{display: 'flex', gap: '8px', justifyContent: 'space-between', marginTop: '-4px'}}>
                                <span>{formik.values.pdf_file}</span>
                                <img
                                  src={RemoveIcon}
                                  alt='Remove'
                                  width='20px'
                                  height='20px'
                                  onClick={onRemovePdfFile}
                                  style={{cursor: 'pointer'}}
                                />
                            </Box>
                          ) : ''}
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                          <CustomFileUpload
                            id='logoUrl'
                            onChange={onLogoSelect}
                            title='Select Logo for your site'
                            subtext='Tap to upload an image file'
                            acceptType='image/*'
                            error={raceLogoError}
                          />
                          {neurunEdition?.guid && typeof selectedLogo === 'string' ? (
                            <Box
                              sx={{
                                  display: 'flex',
                                  gap: '8px',
                                  justifyContent: 'space-between',
                                  marginTop: '-4px',
                                  alignItems: 'center',
                              }}
                            >
                                <Box display="flex" alignItems="center">
                                    <img
                                      src={selectedLogo}
                                      style={{width: '50px', height: '50px', objectFit: 'cover'}}
                                    />
                                    <span style={{marginLeft: '8px'}}>{selectedLogoName}</span>
                                </Box>
                                <img
                                  src={RemoveIcon}
                                  alt="Remove"
                                  width="20px"
                                  height="20px"
                                  onClick={onRemoveLogoFile}
                                  style={{cursor: 'pointer'}}
                                />
                            </Box>
                          ) : selectedLogo && selectedLogo?.length > 0 && Array.isArray(selectedLogo) && (
                                <Box display="flex" flexDirection="column" gap={2}>
                                    {selectedLogo?.map((image, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            gap: '8px',
                                            justifyContent: 'space-between',
                                            marginTop: '-4px',
                                            alignItems: 'center',
                                        }}
                                      >
                                          <Box display="flex" alignItems="center">
                                              <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Selected ${index}`}
                                                style={{width: '50px', height: '50px', objectFit: 'cover'}}
                                              />
                                              <span style={{marginLeft: '8px'}}>{image.name}</span>
                                          </Box>
                                          <img
                                            src={RemoveIcon}
                                            alt="Remove"
                                            width="20px"
                                            height="20px"
                                            onClick={onRemoveLogoFile}
                                            style={{cursor: 'pointer'}}
                                          />
                                      </Box>
                                    ))}
                                </Box>
                              )}
                      </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                      {/*<div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>*/}
                      {/*    <CustomFileUpload*/}
                      {/*      id='imageUpload'*/}
                      {/*      onChange={onRaceImageChange}*/}
                      {/*      title='Select Race Image'*/}
                      {/*      subtext='Tap to upload a race image'*/}
                      {/*      acceptType='image/*'*/}
                      {/*      error={imageErrorMessage}*/}
                      {/*      multiple*/}
                      {/*    />*/}
                      {/*    {selectedImages.length > 0 && (*/}
                      {/*      <Box display="flex" flexDirection="column" gap={2}>*/}
                      {/*          {selectedImages.map((image, index) => (*/}
                      {/*            <Box*/}
                      {/*              key={index}*/}
                      {/*              sx={{*/}
                      {/*                  display: 'flex',*/}
                      {/*                  gap: '8px',*/}
                      {/*                  justifyContent: 'space-between',*/}
                      {/*                  marginTop: '-4px',*/}
                      {/*                  alignItems: 'center',*/}
                      {/*              }}*/}
                      {/*            >*/}
                      {/*                <Box display="flex" alignItems="center">*/}
                      {/*                    <img*/}
                      {/*                      src={URL.createObjectURL(image)}*/}
                      {/*                      alt={`Selected ${index}`}*/}
                      {/*                      style={{width: '50px', height: '50px', objectFit: 'cover'}}*/}
                      {/*                    />*/}
                      {/*                    <span style={{marginLeft: '8px'}}>{image.name}</span>*/}
                      {/*                </Box>*/}
                      {/*                <img*/}
                      {/*                  src={RemoveIcon}*/}
                      {/*                  alt="Remove"*/}
                      {/*                  width="20px"*/}
                      {/*                  height="20px"*/}
                      {/*                  onClick={() => onRemoveImageFile(index)}*/}
                      {/*                  style={{cursor: 'pointer'}}*/}
                      {/*                />*/}
                      {/*            </Box>*/}
                      {/*          ))}*/}
                      {/*      </Box>*/}
                      {/*    )}*/}
                      {/*</div>*/}
                  </Grid>
                  <Grid item xs={12}>
                      <ChipInputComponent
                        chips={formik.values.resource_urls}
                        onAddChip={handleAddChip}
                        onDeleteChip={handleDeleteChip}
                        title='Resource URLs'
                        placeholder='Enter Resource URL'
                        // @ts-ignorez
                        error={formik.errors.resource_urls ? formik.errors.resource_urls : ''}
                      />
                  </Grid>
                  <Grid item md={12} xs={12} style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                      {startPoint && endPoint ? (
                        <CustomMap
                          startPoint={startPoint}
                          endPoint={startPoint}
                          path={path}
                          waypoints={waypoints}
                          onUpdatePath={handleUpdatePath}
                          gpxGuid={gpxGuid}
                          gpxTracks={gpxTracks}
                          saveGpx={saveGpx}
                          setGpxUpdated={setGpxUpdated}
                          setSaveGpx={setSaveGpx}
                          onUpdateGpxComplete={handleGpxUpdateComplete}
                          raceUnit={formik.values.units}
                        />
                      ) : null}
                  </Grid>
              </Grid>
              <button type="submit" disabled={isDisabled || isRaceSaving || !startDateError.valid} style={styles.submitBtn}>Submit</button>
          </form>
        )
    }

    return (
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img src={Logo} alt='Logo'/>
          {renderContent()}
      </Box>
    )
}

const styles = {
    submitBtn: {
        marginTop: '16px',
        padding: '14px',
        width: '100%',
        fontWeight: '500',
        fontSize: '17px',
        borderRadius: '12px',
        border: 'none',
        background: 'linear-gradient(to right, #4A55BA, #6271FF)',
        color: '#FFF',
        cursor: 'pointer',
    },
    requiredField: {
        color: 'red',
        marginLeft: '8px'
    },
    copyBtn: {
        padding: '14px',
        fontWeight: '500',
        fontSize: '17px',
        borderRadius: '12px',
        border: 'none',
        background: '#E0E3FF',
        color: '#6271FF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer'
    },
    copyIcon: {
        filter: 'invert(42%) sepia(49%) saturate(983%) hue-rotate(200deg) brightness(101%) contrast(104%)',
        width: '18px',
        height: '18px',
    }
}

export default HomePage
